import * as React from "react"
import * as styles from './krec.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import {SiSpotify, SiApplemusic, SiYoutubemusic, SiTidal, SiDeezer} from 'react-icons/si';
import Logo from "../../images/assets/logo_outline.svg"
import { Link } from 'gatsby'
import SEO from "../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"




const streamKrec = () => {

    const streamLinks = {
        spotify: "https://open.spotify.com/album/5m92DOHLxyzPtnxFu5Ed1D",
        appleMusic: "https://music.apple.com/pl/album/kr%C4%99%C4%87-single/1641810221",
        youtubeMusic: "https://music.youtube.com/playlist?list=OLAK5uy_l5CUl2jk3MYjbdEAQqkscacZSIbBMfSro&feature=share",
        tidal: "https://tidal.com/browse/album/245025171",
        deezer: "https://www.deezer.com/en/album/350250407"
    }

    return (
        <div className={styles.lnkContainer}>
            <SEO title="Pastwisko" bodyClass={styles.bodyCol}/>
            <div className={styles.lnkWrapper}>
                <StaticImage
                    alt="The artwork of Krec, the comeback single"
                    src="../../images/krec_artwork.jpg"
                /> 

                <h1 className={styles.titl}>KRĘĆ</h1>

                <div className={styles.streamList}>
                    <OutboundLink href={streamLinks.spotify} tabIndex="0" className={styles.streamIco} aria-label="Stream Spotify"><SiSpotify aria-hidden="true" size="2rem"/>
                        <span>SPOTIFY</span></OutboundLink>
                    <OutboundLink href={streamLinks.appleMusic} tabIndex="0" className={styles.streamIco} aria-label="Stream Apple Music"><SiApplemusic aria-hidden="true" size="2rem"/>
                        <span>APPLE MUSIC</span></OutboundLink>
                    <OutboundLink href={streamLinks.youtubeMusic} tabIndex="0" className={styles.streamIco} aria-label="Stream Youtube Music"><SiYoutubemusic aria-hidden="true" size="2rem"/>
                        <span>YOUTUBE MUSIC</span></OutboundLink>
                    <OutboundLink href={streamLinks.tidal} tabIndex="0" className={styles.streamIco} aria-label="Stream Tidal"><SiTidal aria-hidden="true" size="2rem"/>
                        <span>TIDAL</span></OutboundLink>
                    <OutboundLink href={streamLinks.deezer} tabIndex="0" className={styles.streamIco} aria-label="Stream Deezer"><SiDeezer aria-hidden="true" size="2rem"/>
                        <span>DEEZER</span></OutboundLink>
                </div>

                <div className={styles.hl}></div>

                <div className={styles.credits}>
                    <div className={styles.logo} ><Link to="/" aria-label="Ambaras Strona Głowna"><Logo aria-hidden="true" /></Link></div>
                    <p className={styles.copyright}>℗ 2022 AMBARASSED RECORDS</p>
                </div>
            </div>
        </div>
    )
}

export default streamKrec
