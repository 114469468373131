// extracted by mini-css-extract-plugin
export var bodyCol = "krec-module--body-col--cqrHa";
export var copyright = "krec-module--copyright--ERuCW";
export var credits = "krec-module--credits--jYEgR";
export var hl = "krec-module--hl--sSk5R";
export var lnkContainer = "krec-module--lnk-container--10K0R";
export var lnkWrapper = "krec-module--lnk-wrapper--nZiQu";
export var logo = "krec-module--logo--dvsq2";
export var streamIco = "krec-module--stream-ico--uvYoi";
export var streamList = "krec-module--stream-list--Z7Omo";
export var titl = "krec-module--titl--Uo4NI";